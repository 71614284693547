<ng-container *transloco="let t">
  @if(showHeader()) {
    <header [class]="customStyle()" [ngClass]="{ 'desktop-header': showHeaderOnDesktop(), 'mobile-header': showHeaderOnMobile() }">
      <ul class="header-content">
        <li class="logo" [ngClass]="{ 'mobile-search-active': isSearchActive() }">
          <a routerLink="/"><img src="/assets/images/in-time-main-logo.png"></a>
        </li>
        <li class="w-[100%]">
          <ul class="flex justify-end items-center gap-[10px]">
            @if(showSearchOnDesktop()) {
              <li class="desktop-search">
                <input type="text" [placeholder]="t('header.search')" [ngModel]="query()" (ngModelChange)="onQueryChanged($event)">
                <img src="/assets/svg/search-blue.svg">
              </li>
            }
            @if(showSearchOnMobile()) {
              <li class="flex justify-end mobile-search">
                <div class="search-input" [ngClass]="{ 'mobile-search-active': isSearchActive() }">
                  <input type="text" [placeholder]="t('header.search')" [ngModel]="query()" (ngModelChange)="onQueryChanged($event)" #mobileSearchInput>
                  <img class="search-icon" src="/assets/svg/search-blue.svg">
                  <img class="close-icon" src="/assets/svg/icon-close.svg" (click)="onToggleSearch()">
                </div>
                <img src="/assets/svg/magnify.svg" class="magnify" [ngClass]="{ 'mobile-search-active': isSearchActive() }" (click)="onToggleSearch()">
              </li>
            }
            @if(account() != null) {
              <li class="desktop-my-tickets">
                <img src="/assets/svg/tickets-green.svg">
                <a routerLink="/my-tickets">{{ t('header.myTickets') }}</a>
              </li>
              <li class="flex items-center cursor-pointer desktop-account">
                <a class="flex flex-row items-center" [matMenuTriggerFor]="userActions">
                  <p class="user-name mr-[10px]">{{ account()!.firstName }}</p>
                  <div class="flex mr-[5px]">
                    @if(account()?.photo != null) {
                      <img class="user-photo" [src]="account()!.photo!.downloadUrl"/>
                    }
                    @else {
                      <p class="user-initials">{{ account()!.initials }}</p>
                    }
                  </div>
                  <img src="/assets/svg/chevron-down.svg">
                </a>
                <mat-menu #userActions="matMenu">
                  <a mat-menu-item (click)="signOut()">{{ t('header.signOut') }}</a>
                </mat-menu>
              </li>
            }
            @else {
              <li class="flex items-center cursor-pointer">
                <button (click)="signIn()" class="desktop-sign-in-button" [ngClass]="{ 'sign-in-allowed': isSignInAllowed()}">{{ t('header.signIn') }}</button>
              </li>
            }
            <li>
              <div class="language-select flex flex-row items-center gap-[5px] ml-1.5 w-11">
                <a [matMenuTriggerFor]="languageSelect">
                  <span class="fp {{ currentLocale().countryCode | lowercase }}"></span>
                  <img src="/assets/svg/chevron-down.svg">
                </a>
                <mat-menu #languageSelect="matMenu">
                  @for(locale of locales; track locale.name) {
                    <button mat-menu-item class="language" (click)="changeLocale(locale)">
                      <span class="fp {{ locale.countryCode | lowercase }}"></span>
                      {{ locale.displayName }}
                    </button>
                  }
                </mat-menu>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </header>
  }
</ng-container>